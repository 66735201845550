@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;500;600&display=swap');

html, body {
  height: 100%;
  color: #0b0b0b;
}

.fc-app {
  padding: 20px;
  text-align: center;
}

.fc-app-header {
  margin-bottom: 2rem;
}
#logo {
  font-family: 'Poppins', sans-serif;
  margin-bottom: 0;
}
.fc-app-header .fc-tagline {
  font-style: italic;
}

div.fortune {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 10px auto 5px;
  width: auto;
  min-height: 100px;
  background-color: white;
  color: blue;
  text-align: center;
  margin: 2rem auto 0;
  border-radius: 0;
}
.fortune-top {
  display: grid;
  grid-template-columns: 10% 85% 5%;
  grid-template-rows: 10px;
}
.fortune-body {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 60px auto;
}
.fortune-bottom {
  display: grid;
  grid-template-columns: 10% 90%;
  grid-template-rows: 5px;
}
.fortune-top > .box:first-child,
.fortune-bottom>.box:first-child {
  background-color: blue;
}
.fortune-top>.box:last-child {
  background-color: blue;
}

/* Cookie */
.fc-cookie {
  background: transparent;
  display: block;
  margin: 2rem auto 0 auto;
  position: relative;
  width: auto;
  height: 17em;
  border: none;
  filter: drop-shadow(0px 11px 4px rgba(0, 0, 0, .2));
}

.fc-cookie:focus {
  outline: transparent;
}

.fc-part,
.fc-crumbs,
.fc-crumb,
.fc-fortune {
  position: absolute;
  top: 0;
}

.fc-part,
.fc-crumb {
  background: currentColor;
  color: #efaa5d;
}

.fc-part {
  width: 8em;
  height: 18em;
  z-index: 2;
}

.left {
  border-radius: 7em 1em 1em 7em / 50%;
  box-shadow:
    0.5em 0 0 inset,
    0.5em 0.2em 0 inset,
    1em 0.2em 0 #fff6 inset,
    -0.75em 0 0 #0002 inset;
  clip-path: polygon(0% 0%, 68% 0%, 100% 30%, 100% 100%, 0% 100%);
  -webkit-clip-path: polygon(0% 0%, 68% 0%, 100% 30%, 100% 100%, 0% 100%);
  left: calc(50% - 5.4em);
  transform: rotate(25deg);
  transform-origin: 68% 0;
}

.right {
  border-radius: 1em 7em 7em 1em / 50%;
  box-shadow:
    -0.5em 0 0 inset,
    -0.5em 0.2em 0 inset,
    -1em 0.2em 0 #fff6 inset,
    0.75em 0 0 #0002 inset;
  clip-path: polygon(0% 30%, 32% 0%, 100% 0, 100% 100%, 0% 100%);
  -webkit-clip-path: polygon(0% 30%, 32% 0%, 100% 0, 100% 100%, 0% 100%);
  right: calc(50% - 5.4em);
  transform: rotate(-25deg);
  transform-origin: 32% 0;
}

.fc-crumbs {
  left: calc(50% - 0.4em);
  width: 0.8em;
  height: 6em;
  z-index: 1;
}

.fc-crumb {
  border-radius: 50%;
  width: 0.5em;
  height: 0.5em;
}

.fc-crumb:nth-child(1) {
  top: 4%;
  left: 15%;
}

.fc-crumb:nth-child(2) {
  top: 20%;
  right: 15%;
}

.fc-crumb:nth-child(3) {
  top: 30%;
  left: 25%;
}

.fc-crumb:nth-child(4) {
  top: 45%;
  right: 25%;
}

.fc-crumb:nth-child(5) {
  top: 55%;
  left: 5%;
}

.fc-crumb:nth-child(6) {
  top: 65%;
  right: 5%;
}

.fc-crumb:nth-child(7) {
  top: 75%;
  left: 30%;
}

.fc-crumb:nth-child(8) {
  top: 90%;
  right: 35%;
}

/* Fortune */
.fc-fortune {
  font-family: serif;
  background:
    linear-gradient(#fff0 .75em, #fff 0 5.25em, #fff0 0),
    linear-gradient(90deg, currentColor 3em, #fff 0 25.25em, currentColor 0);
  color: #00c;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0.75em 1.5em 0.75em 3.75em;
  text-align: center;
  width: 26em;
  height: 6em;
  left: 0;
  transform: scale(0, 1);
  transform-origin: 50% 32%;
}
.fc-fortune-text {
  padding: 10px;
}

.fc-lucky-numbers {
  font-size: .8em;
}

/* Animation States */
.spawned {
  animation: popIn 0.3s linear;
}

.hide {
  display: none;
}

.opened {
  width: 26em;
  height: 6em;
}

.opened .left {
  animation: breakLeft 0.7s ease-in forwards;
}

.opened .right {
  animation: breakRight 0.7s ease-in forwards;
}

.opened .fc-crumbs {
  animation: fallOut 0.7s ease-in forwards;
}

.opened .fc-fortune {
  animation: foldOut 0.7s ease-in forwards;
}

/* Animations */
@keyframes popIn {
  from {
    transform: scale(0);
  }

  75% {
    transform: scale(1.1);
  }

  to {
    transform: scale(1);
  }
}

@keyframes breakLeft {
  from {
    transform: translateX(0) rotate(25deg);
  }

  30%,
  40% {
    transform: translateX(0) rotate(55deg);
  }

  70%,
  80% {
    opacity: 1;
    transform: translateX(-14em) rotate(50deg);
  }

  to {
    opacity: 0;
    transform: translateX(-14em) rotate(50deg);
  }
}

@keyframes breakRight {
  from {
    transform: translateX(0) rotate(-25deg);
  }

  30%,
  40% {
    transform: translateX(0) rotate(-55deg);
  }

  70%,
  80% {
    opacity: 1;
    transform: translateX(14em) rotate(-50deg);
  }

  to {
    opacity: 0;
    transform: translateX(14em) rotate(-50deg);
  }
}

@keyframes fallOut {
  from {
    left: calc(50% - 0.4em);
    width: 0.8em;
    transform: translateY(0);
  }

  30% {
    opacity: 1;
  }

  60%,
  to {
    opacity: 0;
    left: calc(50% - 3em);
    width: 6em;
    transform: translateY(24em);
  }
}

@keyframes foldOut {
  from {
    transform: scale(0.23, 0.7);
  }

  30%,
  40% {
    transform: scale(0.5, 0.7);
  }

  69% {
    transform: scale(1, 0.7);
  }

  74%,
  to {
    transform: scale(1, 1);
  }
}